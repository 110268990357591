<template>
  <div v-if="visible" class="modal-overlay row" :style="dark ? 'background-color: #18110B' : ''">
    <div class="modal-header">
      <slot name="header"></slot>
    </div>
    <div class="modal-content row">
      <div class="modal-body row">
        <div class="model-body-a row">
          <h1>
            <slot name="contentOnly"></slot>
          </h1>
          <h1>
            <slot name="title"></slot>
          </h1>
          <h2>
            <slot name="content"></slot>
          </h2>
        </div>
      </div>
    </div>
    <img
        @click="handleCancel"
        class="btn-close-common pointer"
        loading="lazy"
        alt=""
        :src="require('@/components/modal/close.svg')"
    />
  </div>
</template>

<script>
import { lockScroll, unlockScroll } from '@/assets/js/common'

export default {
  name: 'Modal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible() {
      return this.value
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.lockScroll()
      } else {
        this.unlockScroll()
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    lockScroll() {
      lockScroll();
    },
    unlockScroll() {
      unlockScroll();
    },
  },
  beforeDestroy() {
    this.unlockScroll()
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000B2;
  z-index: 1000;
  flex-direction: column;
}
.modal-header {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 37px;
  background: linear-gradient(180deg, #FFFFFF 33.78%, #FFF3B8 67.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}
.modal-content {
  background-color: transparent;
  padding: 120px 36px 48px 36px;
  border-radius: 8px;
  position: relative;
  max-width: 375px;
  height: 420.57px;
  width: 100%;
  background-image: url("../message-bg@2x.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.btn-close-common{
  margin-top: 18px;
}
.modal-body {
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-align: center;
}
.model-body-a {
  height: 100%;
  flex-direction: column;
}
h1 {
  line-height: 20.7px;
  font-weight: 600;
  font-size: 18px;
}
h2 {
  margin-top: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.1px;
  color: #6D6D6D;
}
</style>
