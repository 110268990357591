<template>
  <div v-if="visible" class="modal-overlay row" @click="handleCancel">
    <div class="modal-header">CONSUME ALL TICKETS</div>
    <div class="modal-content row">
      <div class="modal-body row">
        <div class="model-body-a row">
          <h1>Clicking "Confirm" will consume all your amusement park tickets</h1>
          <h2>Please note that clicking this option will not result in a lucky draw</h2>
        </div>

        <div class="modal-buttons row">
          <div class="btn btn-white row pointer" style="width: 46%; aspect-ratio: 137 / 36;" @click="handleCancel">Cancel</div>
          <div class="btn btn-orange row pointer" style="width: 46%; aspect-ratio: 137 / 36;" @click="handleConfirm">CONFIRM</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lockScroll, unlockScroll } from '@/assets/js/common'

export default {
  name: 'Modal',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible() {
      return this.value
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.lockScroll()
      } else {
        this.unlockScroll()
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$emit('confirm')
    },
    lockScroll() {
      lockScroll();
    },
    unlockScroll() {
      unlockScroll();
    },
  },
  beforeDestroy() {
    this.unlockScroll()
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #18110B;
  z-index: 1000;
  flex-direction: column;
}
.modal-header {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 37px;
  background: linear-gradient(180deg, #FFFFFF 33.78%, #FFF3B8 67.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}
.modal-content {
  background-color: transparent;
  padding: 120px 36px 48px 36px;
  border-radius: 8px;
  position: relative;
  max-width: 375px;
  height: 420.57px;
  width: 100%;
  background-image: url("../message-bg@2x.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.modal-body {
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-align: center;
}
.model-body-a {
  height: 159px;
  flex-direction: column;
}
h1 {
  line-height: 20.7px;
  font-weight: 600;
  font-size: 18px;
}
h2 {
  margin-top: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.1px;
  color: #6D6D6D;
}
.modal-buttons {
  margin-top: 20px;
  width: 100%;
}
</style>
