<template>
  <div v-if="visible" class="modal-overlay row">
    <div class="modal-lottery-content row">
      <div class="modal-header modal-lottery-header-pc">
        WINNING LOTTERY TICKETS
      </div>
      <div class="modal-header modal-lottery-header-mobile">
        WINNING LOTTERY <br>TICKETS
      </div>

      <div class="modal-body row">
        <div class="modal-lottery-records" @scroll="handleScroll">
          <div>
            <div v-for="(item, index) in tab0Data.data" :key="`0_lottery_records_${index}`" class="records-row row">
              <div class="row">
                <div v-for="(v, i) in item.reward_numbers.split(',')" class="btn-number btn-number-wrapper" :key="`${i}_lottery_number`">{{ v }}</div>
              </div>
              <div class="date-text-pc">
                {{ formatDate(item.reward_time, false) }}
              </div>
              <div class="date-text-mobile">
                {{ formatDate(item.reward_time, false).split(" ")[0] }}<br>
                {{ formatDate(item.reward_time, false).split(" ")[1] }}
              </div>
            </div>
          </div>
        </div>

        <img
            @click="handleCancel"
            class="btn-close pointer"
            loading="lazy"
            alt=""
            :src="require('@/components/modal/close.svg')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { lockScroll, unlockScroll, throttle, formatDate } from '@/assets/js/common'
import { getUserLotteryCoupons } from "@/apis/common"
import { SPIN_REWARDS } from "@/data/define"

export default {
  name: 'Modal',
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    account: {
      type: String,
    },
  },
  data() {
    return {
      SPIN_REWARDS,
      loading: false,
      size: 20,
      tab0Data: {
        data: [],
        totalPages: 0,
        totalRecords: 0,
      },
      page: 0,
      pageEnd: false,

      formatDate,
    }
  },
  computed: {
    visible() {
      return this.value
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.lockScroll()
        // this.getUserLotteryCoupons()
      } else {
        this.unlockScroll()
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    lockScroll() {
      lockScroll()
    },
    unlockScroll() {
      unlockScroll()
    },
    async getUserLotteryCoupons(page = 0) {
      if (this.loading)
        return

      try {
        this.loading = true
        const res = await getUserLotteryCoupons({
          account: this.account,
          page: page,
          size: this.size,
        })
        const { status, data } = res
        this.page = page

        if (status == 200) {
          const d = JSON.parse(JSON.stringify(data))
          if (page === 0)
            this.tab0Data.data = d.data
          else
            this.tab0Data.data = this.tab0Data.data.concat(d.data)

          this.tab0Data.totalPages = d.totalPages
          this.tab0Data.totalRecords = d.totalRecords
          this.pageEnd = d.data.length < this.size

          console.log(this.tab0Data.data)
        }
      } catch (e) {
        console.log('getUserCoupons catch')
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handleScroll(event) {
      const element = event.target
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
        if (this.pageEnd)
          return

        this.throttledFetchRecords()
      }
    },
    throttledFetchRecords: throttle(function () {
      // this.getUserLotteryCoupons(this.page + 1)
    }, 1000),
  },
  beforeDestroy() {
    this.unlockScroll()
  },
}
</script>

<style lang="css" src="@/components/modal/ModalCouponList/modalCouponList.css"></style>
<style lang="css" src="@/components/modal/ModalLotteryList/modalLotteryList.css"></style>
<style lang="css" src="@/components/modal/ModalLotteryRewards/modalLotteryRewards.css"></style>

<style scoped>
</style>
