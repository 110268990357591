<template>
  <div class="ad-carousel row" @click="goToLink">
    <img :src="currentAd.image" :alt="currentAd.alt" />
  </div>
</template>

<script>
import { getDomainId, getRegisterUrl } from "@/utils"

export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      ads1: [
        { image: require("@/assets/images/banner/1/pc-0.jpg"), alt: 'Ad 1' },
        { image: require("@/assets/images/banner/1/pc-1.jpg"), alt: 'Ad 1' },
        { image: require("@/assets/images/banner/1/pc-2.jpg"), alt: 'Ad 1' },
      ],
      ads2: [
        { image: require("@/assets/images/banner/2/pc-0.jpg"), alt: 'Ad 1' },
        { image: require("@/assets/images/banner/2/pc-1.jpg"), alt: 'Ad 1' },
        { image: require("@/assets/images/banner/2/pc-2.jpg"), alt: 'Ad 1' },
      ],
      ads3: [
        { image: require("@/assets/images/banner/3/pc-0.jpg"), alt: 'Ad 1' },
        { image: require("@/assets/images/banner/3/pc-1.jpg"), alt: 'Ad 1' },
        { image: require("@/assets/images/banner/3/pc-2.jpg"), alt: 'Ad 1' },
      ],
      // currentIndex: 0,
    };
  },
  computed: {
    currentAd() {
      const id = getDomainId()
      if (id === 1)
        return this.ads1[this.index]
      else if (id === 2)
        return this.ads2[this.index]
      else if (id === 3)
        return this.ads3[this.index]
      else
        return this.ads1[this.index]
    },
  },
  methods: {
    goToLink() {
      const url = getRegisterUrl()
      if (url)
        window.open(url, '_blank') // Open the link in a new tab
    },
    nextAd() {
      // this.currentIndex = (this.currentIndex + 1) % 3
    },
  },
  mounted() {
    // Change the ad every 10 seconds
    // setInterval(this.nextAd, 3000)
  },
};
</script>

<style scoped>
.ad-carousel {
  width: 100%;
  height: 100%;
  /*min-height: 244px;*/
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.ad-carousel img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional: add rounded corners to images */
  max-width: 1040px;
  margin: 0 auto;
}
</style>
