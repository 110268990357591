import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import HomeView from '../views/HomeView.vue'
import footer from '@/components/footer/index.vue'

const p = {
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      components: {
        default: HomeView,
        footer,
      },
    },
    {
      path: '/welcome',
      name: 'Welcome',
      component: () => import('@/views/Welcome.vue')
    }
  ]
}

const router = new VueRouter(p)

export default router
