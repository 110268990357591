<template>
  <div id="app" class="app">
    <keep-alive>
      <router-view />
    </keep-alive>

    <router-view name="footer" />
  </div>
</template>

<script>
import { getDomainId } from "@/utils"
export default {
  name: 'index',
  mounted() {
    this.updateFaviconAndTitle()
  },
  methods: {
    updateFaviconAndTitle() {
      let title = ''
      let faviconPath = ''
      const id = getDomainId()

      if (id === 1) {
        faviconPath = '/1.png'
        title = 'HAWKPLAY'
      } else if (id === 2) {
        faviconPath = '/2.png'
        title = 'LAWIN'
      } else if (id === 3) {
        faviconPath = '/3.png'
        title = 'LODIBET'
      } else if (id === 4) {
        title = 'Welcome'
        faviconPath = '/favicon.png'
      }

      document.title = title
      this.setFavicon(faviconPath)
    },
    setFavicon(href) {
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = href
    }
  }
}
</script>

<style scoped>
</style>
