export const getDomainId = () => {
  const hostname = window.location.hostname
  if (hostname.includes('hawkplaybermonth.com') || hostname.includes('hawkplaybermonth88.com') || hostname.includes('hawkplaybermonth888.com')) {
    return 1
  } else if (hostname.includes('lawinplaybermonth.com') || hostname.includes('lawinplaybermonth88.com')  || hostname.includes('lawinplaybermonth888.com')) {
    return 2
  } else if (hostname.includes('lodibetbermonth.com') || hostname.includes('lodibetbermonth88.com')  || hostname.includes('lodibetbermonth888.com')) {
    return 3
  } else if (hostname.includes('localhost')) {
    return 2
  } else {
    return 2
  }
}

export const getRegisterUrl = () => {
  const hostname = window.location.hostname
  if (hostname.includes('hawkplaybermonth.com') || hostname.includes('hawkplaybermonth88.com') || hostname.includes('hawkplaybermonth888.com')) {
    return "https://www.hawkplay.com/"
  } else if (hostname.includes('lawinplaybermonth.com') || hostname.includes('lawinplaybermonth88.com')  || hostname.includes('lawinplaybermonth988.com')) {
    return "https://www.lawinplay.com/"
  } else if (hostname.includes('lodibetbermonth.com') || hostname.includes('lodibetbermonth88.com')  || hostname.includes('lodibetbermonth888.com')) {
    return "https://lodibet111.com"
  } else if (hostname.includes('localhost')) {
    return "https://google.com"
  } else {
    return "https://www.hawkplay.com/"
  }
}

export const isYesterday = (lastCouponTime) => {
  const lastCouponDate = new Date(lastCouponTime)
  lastCouponDate.setHours(lastCouponDate.getHours() + 8)

  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  return (
    lastCouponDate.getFullYear() === yesterday.getFullYear() &&
    lastCouponDate.getMonth() === yesterday.getMonth() &&
    lastCouponDate.getDate() === yesterday.getDate()
  )
}

