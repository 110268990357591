<template>
  <div class="tabs-container">
    <div class="tabs row">
      <div
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['tab-button', currentIndex === index ? 'active' : 'tab-inactive', 'row']"
          @click="onChange(index)"
      >
        {{ tab.name }}
        <transition name="fade-bg">
          <div
              v-if="currentIndex === index"
              class="tab-bg"
          ></div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    tabs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  methods: {
    onChange(index) {
      if (this.currentIndex === index)
        return

      this.currentIndex = index
      this.$emit('onChange', index)
    },
  }
}
</script>

<style scoped>
.tabs-container {
  width: 100%;
  position: relative;
  background-color: #fff;
  border-radius: 100px;
  height: 40px;
  padding: 2px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.tabs {
  display: flex;
  position: relative;
  gap: 6px;
}

.tab-button {
  padding: 6px 12px;
  height: 36px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: transparent;
}

.tab-inactive {
  color: #000;
}

.tab-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: -1;
  border-radius: 100px;
}

/* Transition */
.fade-bg-enter-active, .fade-bg-leave-active {
  transition: opacity 0.5s ease;
}

.fade-bg-enter, .fade-bg-leave-to /* .fade-bg-leave-active in <2.1.8 */ {
  opacity: 0;
}

</style>
