export const lockScroll = () => {
  const scrollY = window.scrollY || document.documentElement.scrollTop
  document.body.style.position = 'fixed'
  document.body.style.top = `-${scrollY}px`
  document.body.style.width = '100%'
}

export const unlockScroll = () => {
  const scrollY = Math.abs(parseInt(document.body.style.top || '0', 10))
  document.body.style.position = ''
  document.body.style.top = ''
  window.scrollTo(0, scrollY)
}

export const throttle = (func, delay) => {
  let lastCall = 0
  return function (...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay)
      return

    lastCall = now
    return func.apply(this, args)
  }
}

export const formatDate = (dateString, ignoreSecs = true) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  if (ignoreSecs)
    return `${year}-${month}-${day}`

  const hh = String(date.getHours()).padStart(2, '0')
  const mm = String(date.getMinutes()).padStart(2, '0')
  const ss = String(date.getSeconds()).padStart(2, '0')
  return `${year}-${month}-${day} ${hh}:${mm}:${ss}`
}
