<template>
  <div>
    <div class="mary-wrapper row">
      <img
          class="ranking-top"
          loading="lazy"
          alt=""
          :src="require('@/assets/images/lottery/top.png')"
          :srcset="`${require('@/assets/images/lottery/top.png')} 1x, ${require('@/assets/images/lottery/top@2x.png')} 2x`"
      />
      <div class="mary-description-wrapper ranking-description-wrapper">
        <div class="mary-description-text">
          6 numbers <span>are randomly selected from</span>1 to 47 numbers. If the numbers in the lotto ticket are exactly the same as the winning numbers, you can get up to <span>₱1,000,000</span> in prizes.
        </div>
        <div class="btn btn-orange btn-adapter lottery-btn-adapter row pointer" @click="gotoSpin">GET MORE LOTTO TICKETS?</div>
      </div>

      <div class="lottery-snot-wrapper row">
        <div class="lottery-snot-img">
          <img
              width="100%"
              loading="lazy"
              alt=""
              :src="require('@/assets/images/spin/knot.png')"
          />
        </div>

        <div class="lottery-countdown-wrapper row">
          <div class="time-title row">
            Time remaining before<br>the Grand Prize Draw
          </div>
          <div class="row" style="height: 90%; margin-left: 15px">
            <div class="lottery-gre-amount-wrapper row"><div class="lottery-gre-amount">{{ countDown.day }}</div><div class="lottery-gre-txt">Day</div></div>
            <div class="lottery-gre-amount-wrapper row"><div class="lottery-gre-amount">{{ formattedHours }}</div><div class="lottery-gre-txt">H</div></div>
            <div class="lottery-gre-amount-wrapper row"><div class="lottery-gre-amount">{{ formattedMinutes }}</div><div class="lottery-gre-txt">M</div></div>
            <div class="lottery-gre-amount-wrapper row"><div class="lottery-gre-amount">{{ formattedSeconds }}</div><div class="lottery-gre-txt">S</div></div>
          </div>
        </div>

        <div class="btns row">
          <div class="yellow-btn row" :class="[!user.login ? 'yellow-btn-disable' : 'pointer']" style="margin-right: 20px" @click="checkIsOpenListModal">
            <span class="btn-font">LOTTERY TICKETS I HAVE</span>
          </div>
          <div class="yellow-btn row" :class="[!user.login ? 'yellow-btn-disable' : 'pointer']" style="padding: 0" @click="checkIsOpenRewardModal">
            <span class="btn-font">WINNING LOTTERY TICKETS</span>
          </div>
        </div>

        <div class="lottery-description">
          Live broadcast time: 2024.12.27 (Friday) 19:00
        </div>
      </div>

      <div class="lottery-video-wrapper row">
        <div class="lottery-video-container row">
          <img
              style="object-fit: contain; width: 100%"
              loading="lazy"
              :src="require('@/assets/images/lottery/cover.png')"
          />
<!--          The lottery has not been drawn yet,-->
<!--          <br>so stay tuned-->
<!--          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/rzno1FOZcrk?si=E6bjIR4QUP_0ihf_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>-->
        </div>
      </div>

      <div class="prizes-wrapper ranking-list-wrapper row">
        <div class="ranking-list-container row">
          <div v-for="(v, index) in rankingList.data.slice(0, halfLength)" :key="`prizes_${index}`" :class="['ranking-list-row', 'row', index === 0 ? 'ranking-list-row-first' : '']">
            <div class="left row">
              <div v-if="v.ranking <= 3" class="row"><img :src="RANKING_CROWN[v.ranking-1].img"/></div>
              <div v-else class="row">{{ v.ranking }}</div>
              <span>{{ v.name }}</span>
            </div>
            <span class="row" style="height: 60%"><i>POINTS：</i><i :class="[index < 3 ? 'ranking-list-row-amount' : '']">{{ v.count.toLocaleString() }}</i></span>
          </div>
        </div>
        <div class="ranking-list-container row">
          <div v-for="(v, index) in rankingList.data.slice(halfLength)" :key="`prizes_${index}`" class="ranking-list-row row">
            <div class="left row">
              <div class="row">{{ v.ranking }}</div>
              <span class="row">{{ v.name }}</span>
            </div>
            <span class="row"><i>POINTS：{{ v.count.toLocaleString() }}</i></span>
          </div>
        </div>
      </div>

      <div class="prizes-wrapper row" style="margin-top: -5px">
        <div class="prizes-title-wrapper lottery-title-wrapper">
          <span class="prizes-title-shadow">LOTTERY NUMBERS</span>
          <span class="prizes-title">LOTTERY NUMBERS</span>
        </div>
        <div class="row">
          <div v-for="index in 6" :key="`btn-number-wrapper${index}`" class="btn-number btn-number-wrapper row">?</div>
        </div>
      </div>

      <div class="prizes-wrapper row" style="margin-top: 10px">
        <div class="prizes-title-wrapper lottery-title-wrapper">
          <span class="prizes-title-shadow">BONUS TABLE</span>
          <span class="prizes-title">BONUS TABLE</span>
        </div>

        <div class="list-of-prizes-wrapper">
          <div class="list-of-prizes-title row">
            <div class="row">ITEM</div>
            <div class="row">RULES</div>
            <div class="row">BONUS</div>
          </div>
          <div>
            <div
                v-for="(v, index) in LOTTERY_BONUS"
                :key="`${index}_LOTTERY_BONUS`"
                class="list-of-prizes-body-row row">
              <div class="row" style="border-right: 1px solid #C9C9C9" :style="index === LOTTERY_BONUS.length - 1 ? 'border-radius: 0 0 0 20px' : ''">{{ v.item }}</div>
              <div class="row" style="border-right: 1px solid #C9C9C9; text-align: center">{{ v.rules }}-digit<br>correct number</div>
              <div class="row" :style="index === LOTTERY_BONUS.length - 1 ? 'border-radius: 0 0 20px 0' : ''">{{ v.bonus }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalCommon v-model="showModal" @cancel="showModal = false" :dark="true">
      <template v-slot:title>
        The event has ended, thank you for your enthusiastic participation
      </template>
    </ModalCommon>

    <ModalLotteryList
        v-model="showLotteryListModal"
        :account="user.account"
        @cancel="showLotteryListModal = false"
    />

    <ModalLotteryRewards
        v-model="showLotteryRewardsModal"
        :account="user.account"
        @cancel="showLotteryRewardsModal = false"
    />
  </div>
</template>

<script>
import {LOTTERY_BONUS} from "@/data/define"
import {defaultUser} from "@/data/user"
import ModalCommon from "@/components/modal/ModalCommon/index"
import ModalLotteryList from "@/components/modal/ModalLotteryList"
import ModalLotteryRewards from "@/components/modal/ModalLotteryRewards"

export default {
  name: "index",
  props: {
    user: {
      type: Object,
      default: () => defaultUser
    }
  },
  components: {
    ModalCommon,
    ModalLotteryList,
    ModalLotteryRewards,
  },
  computed: {
    halfLength() {
      return Math.ceil(this.rankingList.data.length / 2)
    },
    formattedHours() {
      return this.countDown.h < 10 ? '0' + this.countDown.h : this.countDown.h
    },
    formattedMinutes() {
      return this.countDown.m < 10 ? '0' + this.countDown.m : this.countDown.m
    },
    formattedSeconds() {
      return this.countDown.s < 10 ? '0' + this.countDown.s : this.countDown.s
    },
  },
  data() {
    return {
      showLotteryListModal: false,
      showLotteryRewardsModal: false,
      showModal: false,
      rankingList: {
        data: [],
        user: {
          amount: 0,
          ranking: '-',
          rewardAmount: '-',
        }
      },
      LOTTERY_BONUS,
      loading: false,
      countDown: {
        day: 0,
        h: 0,
        m: 0,
        s: 0,
      },
      targetDate: new Date('2024-12-27T19:00:00'),
      countDownEnd: false,
    }
  },
  mounted() {
    this.startCountdown()
  },
  activated() {
  },
  watch: {
  },
  methods: {
    startCountdown() {
      this.updateCountdown()
      setInterval(this.updateCountdown, 1000)
    },
    updateCountdown() {
      const currentDate = new Date()
      const timeDifference = this.targetDate - currentDate

      if (timeDifference > 0) {
        // 計算天、時、分、秒
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)

        // 更新 countDown 的值
        this.countDown.day = days
        this.countDown.h = hours
        this.countDown.m = minutes
        this.countDown.s = seconds
      } else {
        // 當時間到期時，將倒計時設置為 0
        this.countDown.day = 0
        this.countDown.h = 0
        this.countDown.m = 0
        this.countDown.s = 0
        this.countDownEnd = true
      }
    },
    gotoSpin() {
      const targetDate = new Date('2024-12-27T19:00:00')
      const currentDate = new Date()
      if (currentDate > targetDate)
        this.showModal = true
      else
        this.$emit('indexChange', 0)
    },
    checkIsOpenListModal() {
      if (!this.user.login)
        return

      this.showLotteryListModal = true
    },
    checkIsOpenRewardModal() {
      if (!this.user.login)
        return

      this.showLotteryRewardsModal = true
    },
  },
  beforeDestroy() {
  }
}
</script>

<style lang="css" src="@/assets/styles/ranking.css"></style>
<style lang="css" src="@/assets/styles/lottery.css"></style>

<style scoped>

</style>
