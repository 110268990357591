export const SPIN_REWARDS = [
  { img: require("@/assets/images/rewards/big.png"), str: "BER MONTH", amount: "₱5,888" },
  { img: require("@/assets/images/rewards/bar.svg"), str: "", amount: "₱2,888" },
  { img: require("@/assets/images/rewards/77.svg"), str: "", amount: "₱888" },
  { img: require("@/assets/images/rewards/star.svg"), str: "STAR", amount: "₱588" },
  { img: require("@/assets/images/rewards/mango.png"), str: "MANGO", amount: "₱388" },
  { img: require("@/assets/images/rewards/watermelon.svg"), str: "WATERMELON", amount: "₱88" },
  { img: require("@/assets/images/rewards/apple.svg"), str: "APPLE", amount: "₱58" },
  { img: require("@/assets/images/rewards/guava.png"), str: "GUAVA", amount: "₱38" },
  { img: require("@/assets/images/rewards/orange.svg"), str: "ORANGE", amount: "₱18" },
  { img: require("@/assets/images/rewards/grape.png"), str: "GRAPE", amount: "₱8" },
  { img: require("@/assets/images/rewards/cherry.png"), str: "CHERRY", amount: "LOTTERY TICKET X1" },
  { img: require("@/assets/images/rewards/ring.png"), str: "BELLS", amount: "TRY YOUR LUCKY AGAIN!" },
  { img: require("@/assets/images/rewards/bar-straight.svg"), str: "", amount: "" },
  { img: require("@/assets/images/rewards/bars.svg"), str: "", amount: "₱2,888" },
]

export const RANKING_CROWN = [
  { img: require("@/assets/images/ranking/ranking-1.png") },
  { img: require("@/assets/images/ranking/ranking-2.png") },
  { img: require("@/assets/images/ranking/ranking-3.png") },
]

export const LOTTERY_PRIZES = [
  { ranking: "1", bonus: "₱200,000" },
  { ranking: "2", bonus: "₱100,000" },
  { ranking: "3", bonus: "₱80,000" },
  { ranking: "4-10", bonus: "₱30,000" },
  { ranking: "11-20", bonus: "₱8,000" },
  { ranking: "21-50", bonus: "₱3,000" },
  { ranking: "51-100", bonus: "₱1,000" },
  { ranking: "101-200", bonus: "₱500" },
  { ranking: "201-300", bonus: "₱200" },
  { ranking: "301-400", bonus: "₱100" },
  { ranking: "401-500", bonus: "₱50" },
  { ranking: "501-1000", bonus: "₱30" },
  { ranking: "1001-2000", bonus: "₱10" },
]

export const LOTTERY_BONUS = [
  { item: "JACKPOT", rules: "6", bonus: "₱1,000,000" },
  { item: "2rd Prize", rules: "5", bonus: "₱3,888" },
  { item: "3rd Prize", rules: "4", bonus: "₱88" },
]

export const SPIN_MP4 = [
  require("@/assets/images/mp4/1.gif"),
  require("@/assets/images/mp4/1.gif"),
  require("@/assets/images/mp4/2.gif"),
  require("@/assets/images/mp4/3.gif"),
  require("@/assets/images/mp4/3.gif"),
]



