<template>
  <footer class="row">
    <div class="footer-wrapper row">
      <div class="term row">TERMS & CONDITIONS</div>
      <div class="description">
        1. Bonus wagering requirement is 1x
        <br>2. The amusement park tickets you receive must be used before DEC. 31 23:59.
        <br>3. We will not provide bonuses to accounts that exploit system vulnerabilities or engage in illegal activities. In the worst-case scenario, the account will be frozen.
        <br>4. Please contact customer service for any event questions.
        <br>5. If the above restrictions occur, the event organizer has the right to confiscate your bonus.
        <br>6. The website reserves the right to interpret the event.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "index"
}
</script>

<style>
</style>

<style scoped>
footer {
  background: linear-gradient(90deg, rgba(61, 17, 11, 0.9) 0%, rgba(254, 17, 11, 0.9) 50%, rgba(61, 17, 11, 0.9) 100%),
  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  padding: 3rem 12px 36px 12px;
  color: #fff;
}

.footer-wrapper {
  width: 100%;
  max-width: 880px;
  padding: 20px;
  font-family: "Roboto", sans-serif;
}

.term {
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  margin-right: 30px;
}

.description {
  font-size: clamp(14px, 4vw, 16px);
  line-height: 28px;
}

@media screen and (max-width: 1040px) {
  .footer-wrapper {
    flex-direction: column;
  }
  .term {
    margin-bottom: 20px;
  }
}
</style>
