<template>
  <div>
    <div class="section-2">
      <div class="instruction-wrapper">
        <div class="instruction-title-wrapper row">
          <div class="instruction-title">
            INSTRUCTIONS FOR OBTAINING EVENT TICKETS
          </div>
        </div>
        <div class="instruction-body-wrapper">
          <div class="instruction-body-row">
            <div class="instruction-body-title-wrapper row">
              <img
                  loading="lazy"
                  :src="require('@/assets/images/spin/section-2/title-1.svg')"
              />
              <div class="instruction-body-title-text">Daily login is a courtesy</div>
            </div>
            <div class="instruction-body-content row">
              <div class="instruction-body-content-img-container col">
                <img
                    loading="lazy"
                    alt=""
                    :src="require('@/assets/images/spin/section-2/1.png')"
                    :srcset="`${require('@/assets/images/spin/section-2/1.png')} 1x, ${require('@/assets/images/spin/section-2/1@2x.png')} 2x`"
                />
              </div>
              <div class="instruction-body-content-text">
                During the event, log in every day and <span>recharge any amount</span> to get <span>X1 amusement park coupon</span> and <span>X1 Lotto ticket</span>
              </div>
            </div>
          </div>
          <div class="instruction-body-row">
            <div class="instruction-body-title-wrapper row">
              <img
                  loading="lazy"
                  :src="require('@/assets/images/spin/section-2/title-2.svg')"
              />
              <div class="instruction-body-title-text">Give away gifts when you play the game</div>
            </div>
            <div class="instruction-body-content row">
              <div class="instruction-body-content-img-container col">
                <img
                    loading="lazy"
                    alt=""
                    :src="require('@/assets/images/spin/section-2/2.png')"
                    :srcset="`${require('@/assets/images/spin/section-2/2.png')} 1x, ${require('@/assets/images/spin/section-2/2@2x.png')} 2x`"
                />
              </div>
              <div class="instruction-body-content-text">
                During the event, every daily <span>cumulative bet of 5,000</span> or more in any game hall will receive <span>X1 amusement park coupon </span>and<span> X1 Lotto ticket</span>
                <p>(Accumulated bets are calculated separately every day and cleared to 0 the next day)</p>
              </div>
            </div>
          </div>
          <div class="instruction-body-row instruction-body-row-3" :style="{ display: showInstructionAppend ? 'block' : 'none' }">
            <div class="instruction-body-title-wrapper row">
              <img
                  loading="lazy"
                  :src="require('@/assets/images/spin/section-2/title-3.svg')"
              />
              <div class="instruction-body-title-text">USDT deposit bonus</div>
            </div>
            <div class="instruction-body-content row">
              <div class="instruction-body-content-img-container col">
                <img
                    loading="lazy"
                    alt=""
                    :src="require('@/assets/images/spin/section-2/3.png')"
                    :srcset="`${require('@/assets/images/spin/section-2/3.png')} 1x, ${require('@/assets/images/spin/section-2/3@2x.png')} 2x`"
                />
              </div>
              <div class="instruction-body-content-text">
                During the event, if the accumulated <span>USDT deposit reaches 3,000 pesos</span>, you will receive <span>X1 amusement park coupon</span> and <span>X1 lottery ticket.</span>
              </div>
            </div>
          </div>
          <img
              v-show="showInstructionAppend === false"
              @click="showInstructionAppend = !showInstructionAppend"
              class="instruction-body-append-img pointer"
              loading="lazy"
              :src="require('@/assets/images/svg/arrow-down.svg')"
          />
        </div>
      </div>
      <div class="userinfo-wrapper row">
        <img
            style="width: 100%"
            loading="lazy"
            alt=""
            :src="require('@/assets/images/spin/section-2/userinfo/bg-top@2x.png')"
        />
        <div class="userinfo-container row">
          <div style="height: 62px; width: 75%; justify-content: flex-start" class="row">
            <div class="row">
              <img
                  style="width: 50px; margin-right: 8px"
                  loading="lazy"
                  alt=""
                  :src="require('@/assets/images/spin/section-2/userinfo/avatar@2x.png')"
              />
            </div>
            <div class="userinfo-container-1">
              <span>{{ user.account }}</span>
              <i><p>Deposit any amount {{ user.any_deposit > 0 ? 1 : 0 }} /1</p></i>
            </div>
          </div>
          <div class="userinfo-container-2 row">
            <i><span>Today’s betting amount</span></i>
            <div class="userinfo-container-2-bar-container row">
              <div class="userinfo-container-2-bar-bg-container">
                <div class="userinfo-container-2-bar-bg-content"
                     :style="{ width: `${getBetPercent()}%` }" ></div>
              </div>
              <div class="userinfo-container-2-amount">
                <span :style="{color: getTodayBetAmount() === 0 ? 'rgb(157, 157, 157)' : ''}">{{ getTodayBetAmount() }}</span>/{{ getTodayBetAmountMax() }}
              </div>
            </div>
          </div>
          <div class="userinfo-container-2 row">
            <i><span>Deposit today <span style="color: rgb(157, 157, 157)">(USDT)</span></span></i>
            <div class="userinfo-container-2-bar-container row">
              <div class="userinfo-container-2-bar-bg-container">
                <div class="userinfo-container-2-bar-bg-content"
                     :style="{ width: `${getDepositPercent()}%` }" ></div>
              </div>
              <div class="userinfo-container-2-amount">
                <span :style="{color: getTodayDepositAmount() === 0 ? 'rgb(157, 157, 157)' : ''}">{{ getTodayDepositAmount() }}</span>/{{ getTodayDepositAmountMax() }}
              </div>
            </div>
          </div>
          <div class="orange-button-wrapper row">
            <template v-if="user.login">
              <div class="orange-button-l row pointer" @click="showCouponListModal = true">
                MY TICKETS <br>& WINNING STATUS
              </div>
              <div class="orange-button-s row pointer" @click="onLogout" style="font-weight: bold">
                LOGOUT
              </div>
            </template>
            <template v-else>
              <div class="orange-button row pointer" @click="onClickLogin">
                LOGIN / REGISTER
              </div>
            </template>
          </div>
          <div class="userinfo-container-2 row" style="font-size: 12px; font-weight: 400; line-height: 16.2px">
            There may be a delay in your game data, at the very most, this won't last longer than 24 hours.
          </div>

        </div>
      </div>
    </div>

    <div class="mary-wrapper row">
      <img
          class="mary-top"
          loading="lazy"
          alt=""
          :src="require('@/assets/images/spin/top.png')"
          :srcset="`${require('@/assets/images/spin/top.png')} 1x, ${require('@/assets/images/spin/top@2x.png')} 2x`"
      />
      <div class="mary-description-wrapper row">
        <div class="mary-description-text">
          During the event, as long as you have an <span>amusement park</span> ticket, you can click below to spin once or ten times. When the movement stops, you will get the prize corresponding to the picture.
        </div>
        <div class="mary-description-sub-text row">
          The bonus you receive from the lottery may be delayed and will be added to your account not later than 24 hours.
        </div>
      </div>

      <div class="mary-snot-wrapper row">
        <div class="mary-snot-img">
          <img
              width="100%"
              loading="lazy"
              alt=""
              :src="require('@/assets/images/spin/knot.png')"
          />
        </div>
        <div class="mary-snot-amount-wrapper row">
          <i>This game received <br>a bonus</i>
          <div class="mary-snot-amount row">
            {{ priceAmount.toLocaleString() }}
          </div>
        </div>
        <div class="mary-snot-amount-wrapper row">
          <i>Tickets obtained <br>by this bureau</i>
          <div class="mary-snot-amount row">
            {{ getCouponAmount.toLocaleString() }}
          </div>
        </div>
        <div class="mary-snot-amount-wrapper row">
          <i>My Amusement <br>Park Tickets</i>
          <div class="mary-snot-amount row">
            {{ user.available_coupon.toLocaleString() }}
          </div>
        </div>
      </div>

      <div class="mary-slot-grid-wrapper">
        <div v-for="(item, index) in maryBG" :key="index" class="mary-slot-grid-item row">
          <img :src="SPIN_REWARDS[item].img"  class="mary-slot-reward-icon" />
          <div v-if="maryHightlightIndex === index" class="mary-slot-reward-highlight-container" />
          <div v-if="maryHightlightIndex === index && rewardIndex === index" class="glow-border" />
        </div>

        <div class="mary-slot-grid-item mary-slot-middle-img-container row">
          <div class="row" style="width: 100%; height: 100%;">
            <img :src="mp4Source" style="object-fit: cover; width: 100%; height: 100%; border-radius: 8px; "/>
          </div>
        </div>
      </div>

      <div class="spin-btns-wrapper row">
        <div class="spin-btn-wrapper" @click="checkSpin(1)"><div class="yellow-btn row" :class="[loading || user.available_coupon === 0 ? 'yellow-btn-disable' : 'pointer']"><span class="spin-btn-text">ONE SPIN</span></div></div>
        <div class="spin-btn-wrapper" @click="checkSpin(10)"><div class="yellow-btn row" :class="[loading || user.available_coupon < 10 ? 'yellow-btn-disable' : 'pointer']"><span class="spin-btn-text">TEN SPIN</span></div></div>
        <div class="spin-btn-wrapper" @click="checkSpin(-1)"><div class="yellow-btn row" :class="[loading || user.available_coupon === 0 ? 'yellow-btn-disable' : 'pointer']"><span class="spin-btn-text">CONSUME ALL TICKETS</span></div></div>
      </div>

      <div class="prizes-wrapper row">
        <div class="prizes-title-wrapper">
          <span class="prizes-title-shadow">RAFFLE PRIZES</span>
          <span class="prizes-title">RAFFLE PRIZES</span>
        </div>
        <div class="prizes-list-wrapper row">
          <div class="prizes-list-row prizes-list-row-first row">
            <div class="prizes-list-row-1"></div>
            <div class="prizes-list-row-2 row" >
              <img :src="SPIN_REWARDS[0].img" style="height: 71%;"/>
              <span>{{ SPIN_REWARDS[0].str }}</span>
            </div>
            <div class="prizes-list-row-3 row"><i class="prizes-list-row-amount row">{{ SPIN_REWARDS[0].amount }}</i></div>
          </div>

          <div class="prizes-list-row row">
            <div class="prizes-list-row-1"></div>
            <div class="prizes-list-row-2 row"><img :src="SPIN_REWARDS[13].img" style="height: 23.45%;"/></div>
            <div class="prizes-list-row-3 row"><i class="prizes-list-row-amount row">{{ SPIN_REWARDS[13].amount }}</i></div>
          </div>

          <div class="prizes-list-row row">
            <div class="prizes-list-row-1"></div>
            <div class="prizes-list-row-2 row"><img :src="SPIN_REWARDS[2].img" style="height: 61%;"/></div>
            <div class="prizes-list-row-3 row"><i class="prizes-list-row-amount row" style="margin-left: 10px">{{ SPIN_REWARDS[2].amount }}</i></div>
          </div>

          <div class="prizes-list-grid row">
            <div v-for="(v, index) in prizesList" :key="`prizes_${index}`" class="prizes-list-grid-row row">
              <div class="prizes-list-grid-row-1 row">
                <img :src="SPIN_REWARDS[v].img"/>
                {{ SPIN_REWARDS[v].str }}
              </div>
              <span class="row"><i>{{ SPIN_REWARDS[v].amount }}</i></span>
            </div>
          </div>

          <div class="prizes-list-row prizes-list-last-row row">
            <div class="prizes-list-grid-row-1 prizes-list-row-2 prizes-list-last-mid row">
              <img :src="SPIN_REWARDS[11].img" style="height: 48%; margin-right: 13px"/>
              {{ SPIN_REWARDS[11].str }}
            </div>
            <i class="row prizes-list-row-last" style="font-size: 16px; color: #F1E8E8;">{{ SPIN_REWARDS[11].amount }}</i>
          </div>

          <img
              class="prizes-list-prize"
              style="z-index: 2; width: 50%; max-width: 210px; position: absolute; right: -35px; bottom: -95px"
              loading="lazy"
              alt=""
              :src="require('@/assets/images/flower.png')"
          />
        </div>
      </div>
    </div>

    <ModalSpinAllConfirm
        v-model="showSpinAllModal"
        @cancel="showSpinAllModal = false"
        @confirm="spin(-1)"
    />

    <ModalCommon v-model="showSpinZeroModal" @cancel="showSpinZeroModal = false">
      <template v-slot:contentOnly>
        You didn’t win this time, but don’t give up—try again!
      </template>
    </ModalCommon>

    <ModalCouponList
        v-model="showCouponListModal"
        :account="user.account"
        @cancel="onListClose"
    />

    <ModalLogin
        v-model="showLoginModal"
        @cancel="showLoginModal = false"
        @login="onLogin"
    />
  </div>
</template>

<script>
import {getLawinUser, getLodiUser, getUser, spin} from "@/apis/common"
import { SPIN_REWARDS, SPIN_MP4 } from "@/data/define"
import ModalSpinAllConfirm from "@/components/modal/ModalSpinAllConfirm/index"
import ModalCommon from "@/components/modal/ModalCommon/index"
import ModalLogin from "@/components/modal/ModalLogin/index"
import ModalCouponList from "@/components/modal/ModalCouponList/index"
import { defaultUser } from "@/data/user"
import {getDomainId, getRegisterUrl, isYesterday} from "@/utils"

export default {
  name: "index",
  components: {
    ModalSpinAllConfirm,
    ModalCommon,
    ModalCouponList,
    ModalLogin,
  },
  data() {
    return {
      showSpinAllModal: false,
      showSpinZeroModal: false,
      showCouponListModal: false,
      showLoginModal: false,

      prizesList: [3, 4, 5, 6, 7, 8, 9, 10],
      SPIN_REWARDS,
      defaultUser,
      user: { ...defaultUser },
      priceAmount: 0,
      getCouponAmount: 0,

      loading: false,
      showInstructionAppend: window.innerWidth >= 769,
      maryBG: [12, 7, 0, 11, 2, 10, 8, 3, 9, 4, 6, 5],
      mp4Source: '',

      maryHightlightIndex: null,
      rewardIndex: null,
      targetIndex: null, // 隨機選取的目標數字
      spinOrder:  [0, 1, 2, 3, 5, 7, 11, 10, 9, 8, 6, 4], // 這個只是格子的順序
      prizeOrder: [1, 7, 0, 11, 2, 10, 8, 3, 9, 4, 6, 5], // 格子裡禮品id
      totalSteps: 70,   // 總步數
      fastSteps: 65,    // 快速步數
      baseInterval: 30, // 快速階段的固定時間間隔
      maxInterval: 300, // 減速階段的最大間隔時間
      intervalIncrement: null, // 每步增加的間隔時間 (在減速階段)

      fastModeTotalSteps: 30,
      fastModeFastSteps: 30,
      fastModeBaseInterval: 16,
      fastModeMaxInterval: 0,
    }
  },
  activated() {
  },
  created() {
    let videoNumber = getDomainId()
    if (videoNumber == null)
      videoNumber = 4
    this.mp4Source = SPIN_MP4[videoNumber]

    this.getAccountFromLocal()
  },
  watch: {
  },
  methods: {
    onClickLogin() {
      // window.location.replace(getRegisterUrl())
      if (getDomainId() === 1) {
        window.location.replace(getRegisterUrl())
      } else {
        this.showLoginModal = true
      }
    },
    getTodayBetAmount() {
      if (this.user.user_from == 1 || this.user.user_from == 3)
        return this.user.today_bet_amount
      else {
        if (this.user.last_coupon_time) {
          if (isYesterday(this.user.last_coupon_time))
            return this.user.today_bet_amount
        }

        return 0
      }
    },
    getTodayBetAmountMax() {
      if (this.user.today_bet_amount == 0)
        return '5000'

      const tmp = Math.floor(this.user.today_bet_amount / 5000)
      return ((tmp+1)*5000).toString()
    },
    getTodayDepositAmount() {
      if (this.user.user_from == 1 || this.user.user_from == 3)
        return this.user.today_deposit_amount
      else {
        if (this.user.last_coupon_time) {
          if (isYesterday(this.user.last_coupon_time))
            return this.user.today_deposit_amount
        }

        return 0
      }
    },
    getTodayDepositAmountMax() {
      if (this.user.today_deposit_amount == 0)
        return '3000'

      const tmp = Math.floor(this.user.today_deposit_amount / 3000)
      return ((tmp+1)*3000).toString()
    },
    getBetPercent() {
      const amount = this.getTodayBetAmount()
      if (amount === 0)
        return 0

      const percent = (amount / this.getTodayBetAmountMax()) * 100
      if (percent >= 98) {
        return 98
      }

      return percent
    },
    getDepositPercent() {
      const amount = this.getTodayDepositAmount()
      if (amount === 0)
        return 0

      const percent = (amount / this.getTodayDepositAmountMax()) * 100
      if (percent >= 98)
        return 98

      return percent
    },
    onListClose() {
      this.showCouponListModal = false
      this.$emit('onListClose')
    },
    onLogin(user) {
      this.user = { ...user }
      this.$emit('login', user)
    },
    tryLogin() {
      if (this.getAccountFromLocal())
        return this.fetchUser(this.user.account)
      else
        return Promise.resolve(this.user)
    },
    onLogout() {
      this.clearUserFromLocal()
      if (getDomainId() === 1) {
        window.location.replace(getRegisterUrl())
      }
      this.priceAmount = 0
      this.getCouponAmount = 0
    },
    clearUserFromLocal() {
      sessionStorage.removeItem('account')
      this.user = { ...defaultUser }
    },
    getAccountFromLocal() {
      const account = sessionStorage.getItem('account')
      if (account) {
        this.user = Object.assign({}, {...defaultUser}, {account}, {login: true})
        // this.$emit('login', this.user)
        return true
      }

      return false
    },
    checkSpin(amount) {
      if (this.loading)
        return

      if (!this.user.login)
        return

      if (this.user.available_coupon === 0)
        return

      if (this.user.available_coupon < amount)
        return

      if (amount !== -1)
        this.spin(amount)
      else
        this.showSpinAllModal = true
    },
    _startSpin(targetIndex, fastMode = false) {
      return new Promise((resolve) => {
        // if (this.loading)
        //   return resolve()

        // this.loading = true
        this.maryHightlightIndex = null
        this.rewardIndex = null
        let stepCount = 0
        const slowSteps = fastMode ? (this.fastModeTotalSteps - this.fastModeFastSteps) : (this.totalSteps - this.fastSteps)
        this.intervalIncrement = fastMode ? (this.fastModeMaxInterval - this.fastModeBaseInterval) / slowSteps : (this.maxInterval - this.baseInterval) / slowSteps
        let currentInterval = fastMode ? this.fastModeBaseInterval : this.baseInterval

        const animateSpin = () => {
          this.maryHightlightIndex = this.spinOrder[stepCount % 12]

          // 如果到達最後一步，讓 currentIndex 停留在 targetIndex
          if ((stepCount >= (fastMode ? this.fastModeTotalSteps : this.totalSteps)) && this.maryHightlightIndex === targetIndex) {
            // this.loading = false
            this.rewardIndex = targetIndex
            resolve()
            return
          }

          stepCount++

          if (stepCount <= fastMode ? this.fastModeFastSteps : this.fastSteps) {
            currentInterval = fastMode ? this.fastModeBaseInterval : this.baseInterval
          } else {
            currentInterval += fastMode ? 0 : this.intervalIncrement
          }

          setTimeout(animateSpin, currentInterval)
        }

        animateSpin()
      })
    },
    async fetchUser(account) {
      if (!this.loading) {
        try {
          this.loading = true
          const platform = getDomainId()
          const param = {
            account: account,
          }

          let res
          if (platform === 1)
            res = await getUser(param)
          else  if (platform === 2)
            res = await getLawinUser(param)
          else  if (platform === 3)
            res = await getLodiUser(param)

          const { status } = res

          if (status == 200) {
            if (res.data.data.length) {
              this.user = Object.assign(this.user, JSON.parse(JSON.stringify(res.data.data[0])))
            }
          }
        } catch (e) {
          console.log('getUser catch')
          console.log(e)
        } finally {
          this.loading = false
          //無論如何都會登入成功
          this.user.login = true
        }
      }

      return this.user
    },
    async spin(amount = 1) {
      try {
        this.loading = true
        const res = await spin({
          account: this.user.account,
          amount,
          user_from: getDomainId(),
        })
        this.loading = false
        const { status } = res

        if (status !== 200)
          return

        if (!res.data.data.length)
          return

        const data = res.data
        if (amount != -1) {
          this.loading = true
          for (let i = 0; i < data.data.length; i++) {
            this.user.available_coupon -= 1
            const targetIndex = this.prizeOrder.indexOf(data.data[i].reward_id)
            // console.log('targetIndex ' + targetIndex + ", reward_id " + data.data[i].reward_id)
            const fastMode = amount === 10
            await this._startSpin(targetIndex, fastMode)

            if (data.data[i].reward_id === 10)
              this.getCouponAmount += 1

            this.priceAmount += data.data[i].reward_price

            await new Promise(resolve => setTimeout(resolve, 800))
          }
          this.loading = false
        } else {
          this.user.available_coupon = 0
          this.getCouponAmount = data.addCoupons
          this.priceAmount = data.totalAmount
        }

        if (data.addCoupons === 0 && data.totalAmount === 0) {
          this.showSpinZeroModal = true
        }
      } catch (e) {
        console.log('spin catch')
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
.glow-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 20px;
  font-size: 24px;
  color: transparent;
  background: transparent;
  border-radius: 10px;
  border: 2px solid transparent;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.7);
  z-index: 1;
  animation: flickerAnimation 0.2s infinite;
}
@keyframes flickerAnimation {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4);
  }
  50% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.1), 0 0 10px rgba(255, 255, 255, 0.05), 0 0 15px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4);
  }
}

@keyframes glow-border {
  0% {
    border-color: rgba(0, 255, 255, 0.1);
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.1);
  }
  100% {
    border-color: rgba(0, 255, 255, 1);
    box-shadow: 0 0 25px rgba(0, 255, 255, 0.7);
  }
}
</style>
