<template>
  <div v-if="visible" class="modal-overlay row">
    <div class="modal-content row">
      <div class="modal-header">
        MY TICKETS<br> & WINNING STATUS
      </div>

      <div class="modal-body row">
        <div style="width: 320px; margin-left: 10px">
          <Tabs
              :tabs="[ { name: 'My ticket' }, { name: 'Winning Record' }, { name: 'Promo Code' }]"
              @onChange="onTabChange" />
        </div>

        <div v-show="tabIndex === 0" class="records" @scroll="handleScroll">
          <div v-for="(item, index) in tab0Data.data" :key="`0_records_${index}`" class="records-row row">
            <img
                loading="lazy"
                :src="ACTIVITY_TYPE[item.activity_id]"
            />
            <div class="records-row-2 row">
              <i><div class="title">{{ TYPE_INDEX[item.get_from] }}</div></i>
              <div class="subtitle">{{ ACTIVITY_TITLE[item.activity_id] }}</div>
            </div>
            <div class="date row">{{ formatDate(item.get_time) }}</div>
          </div>
        </div>

        <div v-show="tabIndex === 1" class="records" @scroll="handleScroll">
          <div v-for="(item, index) in tab1Data.data" :key="`1_records_${index}`" class="records-row row">
            <div class="date-title row" style="text-align: left">{{ formatDate(item.reward_time, false) }}</div>
            <div class="records-row-2 row">
              <img
                  class="fruit"
                  loading="lazy"
                  :src="SPIN_REWARDS[item.reward_id].img"
              />
            </div>
            <i v-if="item.reward_id != 10"><div class="title row">₱{{ item.reward_price }}</div></i>
            <i v-else><div class="title row" style="font-size: 14px"> Lottery ticket X 1</div></i>
          </div>
        </div>

        <div v-show="tabIndex === 2" class="records input-wrapper">
          <div>
            Enter the Promo Code
          </div>
          <div class="input-container">
            <input v-model="code" type="text" class="custom-input">
              <div class="input-button row pointer" @click="createUserPromo">
                REDEEM
              </div>
          </div>
          <div style="height: 30px; color: #C00000; line-height: 12px; margin-top: -3px; margin-left: 10px"><i>{{ codeMsg }}</i></div>
        </div>

        <img
            @click="handleCancel"
            class="btn-close pointer"
            loading="lazy"
            alt=""
            :src="require('@/components/modal/close.svg')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { lockScroll, unlockScroll, throttle, formatDate } from '@/assets/js/common'
import Tabs from "@/components/tabs"
import { getUserCoupons, getUserPrizeCoupons, createUserPromo } from "@/apis/common"
import { SPIN_REWARDS } from "@/data/define"
import { getDomainId } from "@/utils"

export default {
  name: 'Modal',
  components: {
    Tabs,
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    account: {
      type: String,
    },
  },
  data() {
    return {
      SPIN_REWARDS,
      ACTIVITY_TYPE: {
        1: require('./mary.png'),
        2: require('./lottery.png'),
      },
      TYPE_INDEX: [
        '₱3,000 (USDT)',
        'Betting₱5,000',
        'Deposit any amount',
        'Spin prize',
        'Promo code',
        '₱3,000 (USDT)',
        'Betting₱5,000',
        'Deposit any amount',
      ],
      ACTIVITY_TITLE: {
        1: 'Amusement park ticket',
        2: 'Lottery ticket',
      },
      tabIndex: 0,
      loading: false,
      size: 20,
      tab0Data: {
        data: [],
        totalPages: 0,
        totalRecords: 0,
      },
      tab0Page: 0,
      tab0PageEnd: false,
      tab1Data: {
        data: [],
        totalPages: 0,
        totalRecords: 0,
      },
      tab1Page: 0,
      tab1PageEnd: false,
      tab2Data: {},
      tab2Page: 0,
      tab2PageEnd: false,

      code: "",
      codeMsg: "",
      lastErrCode: "",

      formatDate,
    }
  },
  computed: {
    visible() {
      return this.value
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.lockScroll()
        this.getUserCoupons()
      } else {
        this.unlockScroll()
        this.tabIndex = 0
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    lockScroll() {
      lockScroll()
    },
    unlockScroll() {
      unlockScroll()
    },
    onTabChange(index) {
      if (this.tabIndex === index)
        return

      this.tabIndex = index
      this.codeMsg = ""
      this.code = ""
      this.lastErrCode = ""

      if (index === 0)
        this.getUserCoupons()
      else if (index === 1)
        this.getUserPrizeCoupons()
    },
    async getUserCoupons(page = 0) {
      if (this.loading)
        return

      try {
        this.loading = true
        const res = await getUserCoupons({
          account: this.account,
          page: page,
          size: this.size,
          user_from: getDomainId(),
        })
        const { status, data } = res
        this.tab0Page = page

        if (status == 200) {
          const d = JSON.parse(JSON.stringify(data))
          if (page === 0)
            this.tab0Data.data = d.data
          else
            this.tab0Data.data = this.tab0Data.data.concat(d.data)

          this.tab0Data.totalPages = d.totalPages
          this.tab0Data.totalRecords = d.totalRecords
          this.tab0PageEnd = d.data.length < this.size
        }
      } catch (e) {
        console.log('getUserCoupons catch')
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getUserPrizeCoupons(page = 0) {
      if (this.loading)
        return

      try {
        this.loading = true
        const res = await getUserPrizeCoupons({
          account: this.account,
          page: page,
          size: this.size,
          user_from: getDomainId(),
        })
        const { status, data } = res
        this.tab0Page = page

        if (status == 200) {
          const d = JSON.parse(JSON.stringify(data))
          if (page === 0)
            this.tab1Data.data = d.data
          else
            this.tab1Data.data = this.tab1Data.data.concat(d.data)

          this.tab1Data.totalPages = d.totalPages
          this.tab1Data.totalRecords = d.totalRecords
          this.tab1PageEnd = d.data.length < this.size

          console.log(this.tab1Data.data)
        }
      } catch (e) {
        console.log('getUserCoupons catch')
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async createUserPromo() {
      if (this.loading)
        return

      if (!this.code)
        return

      if (this.lastErrCode === this.code && this.codeMsg)
        return

      try {
        this.loading = true
        const res = await createUserPromo({
          account: this.account,
          code: this.code,
          user_from: getDomainId()
        })
        const { status, data } = res
        if (status == 200) {
          if (data.success)
            this.codeMsg = "Success! Your Amusement Park Tickets added."
          else {
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty('exist'))
              this.codeMsg = "the promo code doesn't exist"
            else
              this.codeMsg = "Oops, this promotion code has already been redeemed."
          }
          this.lastErrCode = data.success ? "" : this.code
        }
      } catch (e) {
        console.log('createUserPromo catch')
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handleScroll(event) {
      const element = event.target
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
        if (this.tabIndex === 0 && this.tab0PageEnd)
          return

        if (this.tabIndex === 1 && this.tab1PageEnd)
          return

        this.throttledFetchRecords()
      }
    },
    throttledFetchRecords: throttle(function () {
      if (this.tabIndex === 0) {
        this.getUserCoupons(this.tab0Page + 1)
      }

      if (this.tabIndex === 1)
        this.getUserPrizeCoupons(this.tab1Page + 1)
    }, 1000),
  },
  beforeDestroy() {
    this.unlockScroll()
  },
}
</script>

<style lang="css" src="@/components/modal/ModalCouponList/modalCouponList.css"></style>

<style scoped>

</style>
