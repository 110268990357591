import axios from 'axios'
// import md5 from 'js-md5'
import { Base64 } from 'js-base64'

// const aid = 'fd3498ffe1454553b254380da8eb6832'
// const qid = md5(Date.now() + Math.random().toString().split('.')[1].slice(0, 6))
// const merchantCode = 'dcadcd4bb9'
const authorization = async () => {
  const json = ''
  const token = json?.access_token

  if (token) {
    return `bearer ${token}`
  } else {
    return 'Basic ' + Base64.encode('assets-client:123456')
  }
}

// const sign = (data, aid, qid, authorization) =>
//   md5(
//     JSON.stringify(data).split('').sort().join('').trim() +
//       qid +
//       aid +
//       authorization,
//   )

const instance = axios.create({
  baseURL: '',
})

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
// @ts-ignore
axios.defaults.headers['Accept'] = 'application/json'
axios.defaults.withCredentials = false

instance.interceptors.request.use(
  async config => {
    config.headers['authorization'] = await authorization()
    return config
  },
  error => {
    console.log(error) /* eslint-disable-line */
    return Promise.reject(error)
  },
)

instance.interceptors.response.use((res) => {
  return res
}, function (err) {
  // if (err.response.status === 401 || err.response.status === 403) {
  //
  // }
  return Promise.reject(err)
})

export default instance
